<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="reports_lines"
      class="elevation-0 myTable"
      disable-pagination
      hide-default-footer
      @click:row="handleTableClick"
      :options.sync="options"
      :server-items-length="total"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:no-data>
        Aucune ligne
      </template>
      <template v-slot:top>
        <v-toolbar flat extended extension-height="100">
          <v-toolbar-title id="toolbar-title">Rapports</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="870px">
            <template v-slot:activator="{ on, attrs }">
              <Button
                action_name="Ajouter"
                class="mb-2"
                color="primary"
                text
                v-bind="attrs"
                v-on="on"
                @clicked="openAddDialog(true)"
              />
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline new-report-headline">{{
                  formTitle
                }}</span>
                <v-btn ref="close" color="black" icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="pb-0">
                <v-container class="pb-0">
                  <v-form ref="form" style="width: 100%" v-model="isValid">
                    <v-row>
                      <v-col cols="12" md="4">
                        <AutoComplete
                          id="dossier"
                          label="Dossier"
                          v-model="editedItem.dossier"
                          :items="items"
                          item-text="name"
                          item-value="id"
                          :rules="requiredRules"
                          @change="handleDossierChange"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <AutoComplete
                          id="type"
                          label="Type de rapport"
                          v-model="editedItem.type"
                          item-text="name"
                          item-value="id"
                          :items="reports_types"
                          @change="handleTypeChange"
                          :rules="requiredRules"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <label>Date</label>
                        <custom-date-picker
                          v-model="editedItem.exact_date"
                          :disabled="editedIndex !== -1"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <ckeditor
                          class="custom-ck-editor"
                          @ready="onReady"
                          :editor="editor"
                          v-model="editedItem.content"
                          :config="editorConfig"
                        ></ckeditor>
                      </v-col>
                    </v-row>
                    <v-row v-if="isNewReport">
                      <v-col cols="12" md="12">
                        <v-file-input
                          v-model="currFiles"
                          small-chips
                          show-size
                          multiple
                          clearable
                          flat
                          solo
                          @change="inputChanged"
                          placeholder="Pièces jointes"
                          class="file-upload-input"
                        >
                          <template v-slot:selection="{ text }">
                            <v-chip small label color="primary"
                              >{{ text }}aaa</v-chip
                            >
                          </template>
                          <template v-slot:append="{}">
                            <v-btn class="blue--text text--lighten-1" flat>
                              <v-icon>search</v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:selection="{ text, index }">
                            <v-chip
                              small
                              text-color="white"
                              color="#295671"
                              close
                              @click:close="remove(index)"
                            >
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                        <div v-if="fileTypeError" class="v-text-field__details">
                          <div
                            class="v-messages theme--light error--text"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div
                                class="v-messages__message"
                                style="padding: 0 12px;"
                              >
                                Ce type de fichier n'est pas supporté
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6 ml-6 justify-start">
                <Button
                  action_name="Continuer"
                  color="#11887E"
                  @clicked="save(true)"
                  :loading="loadingContinue"
                  v-if="isNewReport"
                />
                <Button
                  action_name="Ajouter"
                  color="#86CC26"
                  text
                  @clicked="save(false)"
                  :loading="loadingAdd"
                  v-if="isNewReport"
                />
                <Button
                  action_name="Modifier"
                  color="#86CC26"
                  text
                  @clicked="update"
                  :loading="loadingEdit"
                  v-if="!isNewReport"
                />
              </v-card-actions>
            </v-card>
            <v-dialog v-model="showCloseDialog" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Etes vous sûr de fermer cette modal ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="dark darken-5"
                    text
                    @click="showCloseDialog = false"
                    >Annuler</v-btn
                  >
                  <v-btn
                    color="primary darken-5"
                    text
                    @click="handleCloseDialog"
                    >Oui</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-dialog>
          <v-dialog persistent v-model="filterDialog" max-width="836px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">Filtre</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      sm="6"
                      class="ma-0 pb-0 pt-0"
                      v-if="!disabledExactDate"
                    >
                      <label>Date</label>
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0 pt-0 d-inline-block select-multiple-date"
                        v-model="checkDate"
                        @change="handleMultipleDate(true)"
                      ></v-checkbox>
                      <custom-date-picker v-model="filterItem.exact_date" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      class="ma-0 pb-0 pt-0"
                      v-if="showMultipleDates"
                    >
                      <label>Date début</label>
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 pa-0 d-inline-block select-multiple-date"
                        v-model="checkDate"
                        @change="handleMultipleDate(false)"
                      ></v-checkbox>
                      <custom-date-picker v-model="filterItem.start_date" />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      sm="6"
                      class="ma-0 pb-0 pt-0"
                      v-if="showMultipleDates"
                    >
                      <label>Date fin</label>
                      <custom-date-picker v-model="filterItem.end_date" />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="dossier"
                        v-model="filterItem.dossier"
                        :items="dossiers"
                        label="Dossier"
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                        return-object
                      />
                    </v-col>
                    <v-col cols="12" md="6" sm="6" class="ma-0 pt-0 pb-0">
                      <AutoComplete
                        id="type"
                        v-model="filterItem.type"
                        :items="reports_types"
                        label="Type de rapport "
                        item-text="name"
                        multiple
                        deletable-chips
                        small-chips
                        return-object
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pb-6">
                <Button
                  action_name="Filtrer"
                  color="#86CC26"
                  @clicked="filter"
                  style="width: 220px"
                  class="ml-5 mr-5"
                  :loading="loadingFilter"
                />
                <span style="color:#B2BBAB" @click="handleReset"
                  >Réinitialiser</span
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <template v-slot:extension>
            <div class="filter-block">
              <div>
                <template v-for="(type, index) in filterItem.type">
                  <v-chip
                    class="ma-1"
                    :key="type.name"
                    close
                    @click:close="handleChipDelete(index, 'type')"
                    >Type de rapport:{{ type.name }}</v-chip
                  >
                </template>
                <template v-for="(dossier, index) in filterItem.dossier">
                  <v-chip
                    class="ma-1"
                    :key="dossier.name"
                    close
                    @click:close="handleChipDelete(index, 'dossier')"
                    >Dossier:{{ dossier.name }}</v-chip
                  >
                </template>
                <template v-if="filterItem.exact_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleDateDelete('exact_date')"
                    >Date:{{ filterItem.exact_date }}</v-chip
                  >
                </template>
                <template v-if="filterItem.start_date && filterItem.end_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleMultipleDateDelete"
                    >Entre le :{{ filterItem.start_date }} et le :
                    {{ filterItem.end_date }}</v-chip
                  >
                </template>
                <template v-if="filterItem.start_date && !filterItem.end_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleDateDelete('start_date')"
                    >Aprés le :{{ filterItem.start_date }}</v-chip
                  >
                </template>
                <template v-if="filterItem.end_date && !filterItem.start_date">
                  <v-chip
                    class="ma-1"
                    close
                    @click:close="handleDateDelete('end_date')"
                    >Avant le :{{ filterItem.end_date }}</v-chip
                  >
                </template>
              </div>
              <div>
                <v-chip class="chip-delete">
                  <v-icon @click="filterDialog = true" outlined>
                    mdi-filter
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.exact_date="{ item }">
        {{ item.exact_date | moment("DD/MM/YYYY") }}
      </template>
      <template v-slot:item.agent="{ item }">
        <Avatar :item="item.agent" />
        <span class="ml-2">{{ item.agent.name }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <Button
          class="mb-2 mr-3"
          action_name="Modifier"
          color="secondary"
          text
          @clicked="handleOpenEditModal(item)"
        />
        <ReportDetails :report="item" v-model="detailsDialog" />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import {
  Avatar,
  Button,
  AutoComplete,
  CustomDatePicker
} from "@/components/base";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
import { getAllTemplates, getTemplate } from "@/services/reportsTypes";
import { getUserDossiers } from "@/services/dossiers.api";
import {
  addReport,
  getAllUserReports,
  updateReport
} from "@/services/reports.api";
import ReportDetails from "@/components/common/report-details";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

export default {
  components: { Button, Avatar, AutoComplete, CustomDatePicker, ReportDetails },
  mixins: [infinityScrollMixin],
  data: () => ({
    editor: DecoupledEditor,
    editorConfig: {
      toolbar: {
        removeItems: ["uploadImage", "mediaEmbed"],
        shouldNotGroupWhenFull: true
      }
    },
    showItem: {},
    isNewReport: false,
    detailsDialog: false,
    showCloseDialog: false,
    isValid: false,
    loadingAdd: false,
    loadingFilter: false,
    loadingEdit: false,
    loadingContinue: false,
    showEditButton: false,
    currFiles: [],
    files: [],
    reports_types: [],
    fileTypeError: false,
    fileTypes: [
      ".pdf",
      ".rar",
      ".zip",
      ".doc",
      ".docx",
      ".png",
      ".jpeg",
      ".xlsx",
      ".csv"
    ],
    loadingExport: false,
    total: 0,
    options: {},
    disabledExactDate: false,
    showMultipleDates: false,
    checkDate: false,
    dossiers: [],
    filterDialog: false,
    reportLine: [
      {
        report: {
          agent: {
            name: null
          }
        }
      }
    ],
    items: [],
    dialog: false,
    headers: [
      {
        text: "Agent",
        align: "start",
        value: "agent"
      },
      { text: "Role", value: "role.name" },
      { text: "Dossier", value: "dossier.name" },
      { text: "Rapport", value: "reports_types.name" },
      {
        text: "Date",
        value: "exact_date"
      },
      { text: "Actions", value: "actions", sortable: false }
    ],
    reports_lines: [],
    editedIndex: -1,
    filterItem: {},
    editedItem: {
      content: "",
      exact_date: new Date().toISOString().substr(0, 10)
    },
    defaultItem: {
      exact_date: new Date().toISOString().substr(0, 10)
    },
    pageNumber: 1,
    last_page: 0,
    Tablecss:
      "<style>.fix,\n" +
      ".fix > tr > td, .fix > tr > th,\n" +
      '.fix > tbody > tr > td, table[border="0"] > tbody > tr > th,\n' +
      '.fix > thead > tr > td, table[border="0"] > thead > tr > th,\n' +
      '.fix > tfoot > tr > td, table[border="0"] > tfoot > tr > th\n' +
      "{\n" +
      "   border: #d3d3d3 1px dotted ;\n" +
      "}\n" +
      "\n" +
      '/* For tables with no "border" attribute set */\n' +
      ".fix,\n" +
      ".fix > tr > td, table:not([border]) > tr > th,\n" +
      ".fix > tbody > tr > td, .fix > tbody > tr > th,\n" +
      ".fix > thead > tr > td, .fix > thead > tr > th,\n" +
      ".fix > tfoot > tr > td, .fix > tfoot > tr > th\n" +
      "{\n" +
      "   border: #d3d3d3 1px dotted ;\n" +
      "}</style>"
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau rapport" : "Modifier rapport";
    }
  },

  watch: {
    options: {
      handler() {
        this.filterItem.order_by = this.options.sortBy[0];
        this.filterItem.order_by_direction = this.options.sortDesc[0];
        this.reports_lines = [];
        this.pageNumber = 1;
        this.getUserReports();
      },
      deep: true
    },
    handleFileChange() {
      this.fileTypeError = false;
      if (this.editedItem.file) {
        const extension = this.editedItem.file.name.substring(
          this.editedItem.file.name.lastIndexOf(".")
        );
        if (!this.fileTypes.includes(extension)) {
          this.fileTypeError = true;
        }
      }
    },
    endOfTheScreen(endOfTheScreen) {
      if (endOfTheScreen === true && this.pageNumber < this.last_page) {
        this.pageNumber += 1;
        getAllUserReports(this.pageNumber, this.filterItem).then(response => {
          this.reports_lines = [...this.reports_lines, ...response.data.data];
        });
      }
    },
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    handleDossierChange() {
      getAllTemplates(this.editedItem.dossier).then(response => {
        this.reports_types = response.data;
      });
    },
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    filter() {
      this.loadingFilter = true;
      this.pageNumber = 1;
      getAllUserReports(this.pageNumber, this.filterItem).then(response => {
        this.reports_lines = response.data.data;
        this.last_page = response.data.last_page;
        this.total = response.data.total;
        this.loadingFilter = false;
        this.filterDialog = false;
      });
    },
    openAddDialog(dialogStat) {
      this.editedIndex = -1;
      this.isNewReport = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (dialogStat) {
        this.dialog = dialogStat;
        this.$nextTick(() => {
          this.editedItem.exact_date = new Date().toISOString().substr(0, 10);
          const items = [].slice.call(
            document.querySelectorAll(".editr--toolbar .dashboard form")
          );
          items.forEach(function(item) {
            item.addEventListener(
              "click",
              function(e) {
                e.target.focus();
              }.bind(this)
            );
          });
        });
      }
    },
    handleOpenEditModal(item) {
      this.isNewReport = false;
      this.editedIndex = 1;
      getAllTemplates(item.dossier_id).then(response => {
        this.reports_types = response.data;
        this.editedItem = item;
        this.editedItem.type = item.reports_types;
        this.editedItem.exact_date = new Date(item.exact_date)
          .toISOString()
          .substr(0, 10);
        this.dialog = true;
      });
      this.$nextTick(() => {
        const items = [].slice.call(
          document.querySelectorAll(".editr--toolbar .dashboard form")
        );
        items.forEach(function(item) {
          item.addEventListener(
            "click",
            function(e) {
              e.target.focus();
            }.bind(this)
          );
        });
      });
    },
    inputChanged() {
      for (let i = 0; i < this.currFiles.length; i++) {
        this.fileTypeError = false;
        if (this.currFiles[0]) {
          const extension = this.currFiles[0].name.substring(
            this.currFiles[0].name.lastIndexOf(".")
          );
          if (!this.fileTypes.includes(extension)) {
            this.fileTypeError = true;
          }
        }
      }
      let array = this.currFiles.filter(
        el => !this.files.map(file => file.name).includes(el.name)
      );
      this.currFiles = [...new Set([...array, ...this.files])];
      this.files = this.currFiles;
    },
    handleTypeChange(item) {
      getTemplate(item).then(response => {
        this.editedItem.content = response.data;
      });
    },
    handleMultipleDateDelete() {
      this.filterItem["start_date"] = "";
      this.filterItem["end_date"] = "";
      this.save();
    },
    handleMultipleDate(status) {
      if (status === true) {
        this.filterItem["exact_date"] = "";
      } else {
        this.filterItem["start_date"] = "";
        this.filterItem["end_date"] = "";
      }
      this.showMultipleDates = !this.showMultipleDates;
      this.disabledExactDate = !this.disabledExactDate;
    },
    handleReset() {
      this.filterItem = {};
      this.filter();
    },
    handleChipDelete(index, key) {
      this.filterItem[key].splice(index, 1);
      this.filter();
    },
    handleDateDelete(key) {
      this.filterItem[key] = "";
      this.filter();
    },
    handleTableClick(row) {
      this.lineDetail = true;
      this.reportLine = row;
    },
    remove(index) {
      this.currFiles.splice(index, 1);
      this.files = this.currFiles;
    },
    getUserReports() {
      getAllUserReports(this.pageNumber, this.filterItem).then(response => {
        this.reports_lines = response.data.data;
        this.last_page = response.data.last_page;
        this.total = response.data.total;
      });
    },
    initialize() {
      this.getUserReports();
      getUserDossiers().then(response => {
        //TODO REFACTOR IN BACKEND TO REMOVE LOOP
        for (const key in response.data) {
          this.items.push(response.data[key].dossier);
        }
        this.dossiers = this.items;
      });
    },
    close() {
      this.showCloseDialog = true;
    },
    handleCloseDialog() {
      this.filterDialog = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      if (this.fileTypeError) {
        return;
      }
      this.editedItem.file = this.currFiles;
      let formData = new FormData();
      if (this.editedItem.file.length > 0) {
        for (var x = 0; x < this.currFiles.length; x++) {
          formData.append("file[]", this.currFiles[x]);
        }
      }
      // formData.append('file[]', this.currFiles);
      formData.append("exact_date", this.editedItem.exact_date);
      formData.append("content", this.Tablecss + this.editedItem.content);
      formData.append("dossier", this.editedItem.dossier);
      formData.append("type", this.editedItem.type);
      dialogStat ? (this.loadingContinue = true) : (this.loadingAdd = true);
      addReport(formData).then(() => {
        dialogStat ? (this.loadingContinue = false) : (this.loadingAdd = false);
        this.$swal.fire({ title: "Rapport ajouté avec succès" });
        this.currFiles = [];
        this.files = [];
        this.editedItem.file = [];
        this.getUserReports(dialogStat);
        this.dialog = dialogStat;
      });
    },
    update() {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      // if (this.fileTypeError) {
      //   return;
      // }
      // this.editedItem.file = this.currFiles;
      let formData = new FormData();
      // if (this.editedItem.file.length > 0) {
      //   for (var x = 0; x < this.currFiles.length; x++) {
      //     formData.append("file[]", this.currFiles[x]);
      //   }
      // }
      // formData.append('file[]', this.currFiles);
      formData.append("exact_date", this.editedItem.exact_date);
      formData.append("content", this.Tablecss + this.editedItem.content);
      formData.append(
        "dossier",
        typeof this.editedItem.dossier === "object"
          ? this.editedItem.dossier.id
          : this.editedItem.dossier
      );
      formData.append(
        "type",
        typeof this.editedItem.type === "object"
          ? this.editedItem.type.id
          : this.editedItem.type
      );
      this.loadingEdit = true;
      updateReport(this.editedItem.id, formData).then(() => {
        this.loadingEdit = false;
        this.$swal.fire({ title: "Rapport modifié avec succès" });
        this.currFiles = [];
        this.files = [];
        this.editedItem.file = [];
        this.getUserReports();
        this.dialog = false;
      });
    }
  }
};
</script>
<style scoped>
.select-multiple-date {
  margin-top: -5px !important;
}
.file-upload-input.v-file-input >>> .v-file-input__text--placeholder {
  text-decoration: underline;
  font-family: "Segoe UI", serif;
  font-weight: normal;
  font-size: 13px;
  text-align: left;
  color: #003d42;
}
.new-report-headline {
  font-size: 34px !important;
  text-align: left;
  color: #003d42;
}
.custom-ck-editor > #cke_editor1 {
  border-radius: 20px;
  overflow: hidden;
}
.custom-ck-editor #cke_1_top {
  background: white;
}
</style>
